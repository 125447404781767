import { render } from "./RecommendEvent.vue?vue&type=template&id=04a7e4e8&scoped=true"
import script from "./RecommendEvent.vue?vue&type=script&lang=ts"
export * from "./RecommendEvent.vue?vue&type=script&lang=ts"

import "./RecommendEvent.vue?vue&type=style&index=0&id=04a7e4e8&lang=css"
import "./RecommendEvent.vue?vue&type=style&index=1&id=04a7e4e8&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-04a7e4e8"
/* hot reload */
if (module.hot) {
  script.__hmrId = "04a7e4e8"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('04a7e4e8', script)) {
    api.reload('04a7e4e8', script)
  }
  
  module.hot.accept("./RecommendEvent.vue?vue&type=template&id=04a7e4e8&scoped=true", () => {
    api.rerender('04a7e4e8', render)
  })

}

script.__file = "src/page/recommendEvent/RecommendEvent.vue"

export default script