
import promotionMixins from '../../common/mixins/promotion';

export default {
  name: 'RecommendEvent',
  components: {},
  mixins: [promotionMixins],
  data() {
    return { referral_code: '' as string };
  },
  created() {
    this.referral_code = this.$route.params.referral_code;
  },
  methods: {
    pushToLogin() {
      window.location.href = `/home/login?referral_code=${this.referral_code}`;
    },
  },
  computed: {},
};
