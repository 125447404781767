<template>
  <div id="tutoringWrap">
    <section id="tutoringContent"></section>
    <main class="page-recommend">
      <section class="main">
        <div class="textContainer">
          <img class="textImg" src="//res.tutoring.co.kr/res/images/evt/com/invite/img_top_txt.png" alt="title" />
          <p class="text">
            회원 가입하고 무료 첫 수업 하면
            <br />
            총 55,000P를 드려요!
          </p>
        </div>
        <img class="mainImg" src="//res.tutoring.co.kr/res/images/evt/com/invite/img_top_visual.png" alt="title" />
      </section>
      <p class="bottom">※ 현재 페이지를 나가면 포인트 혜택이 사라져요!</p>
      <section class="benefit">
        <img class="titleImg" src="//res.tutoring.co.kr/res/images/evt/com/invite/img_tl_01.png" alt="title" />
        <div class="benefitContainer">
          <div class="cardContainer">
            <p class="sub-title">15분 무료 수업 제공</p>
            <div class="card card-purple">
              <img
                class="free"
                src="//res.tutoring.co.kr/res/images/evt/com/invite/img_w_free__referral.png"
                alt="unlimited"
              />
            </div>
          </div>
          <div class="cardContainer">
            <p class="sub-title">첫 수업 포인트</p>
            <div class="card card-white">
              <p class="point">
                45,000
                <img src="//res.tutoring.co.kr/res/images/evt/com/invite/ic_point_referral.png" alt="point" />
              </p>
              <p class="desc1">가입 후 App에서</p>
              <p class="desc2">무료 첫 수업 완료 시 제공</p>
            </div>
          </div>
          <div class="cardContainer">
            <p class="sub-title">회원가입 포인트</p>
            <div class="card card-white">
              <p class="point">
                10,000
                <img src="//res.tutoring.co.kr/res/images/evt/com/invite/ic_point_referral.png" alt="point" />
              </p>
              <p class="desc1">오직 이 페이지에서</p>
              <p class="desc2">신규 회원 가입 시 제공</p>
            </div>
          </div>
        </div>
        <button @click="pushToLogin()">10초 만에 가입하고 혜택받기</button>
      </section>
      <section class="participation">
        <img
          class="titleImg"
          src="//res.tutoring.co.kr/res/images/evt/com/invite/img_tl_03.png"
          alt="title"
          style="margin-bottom: 40px"
        />
        <div class="card">
          <img class="img270" src="//res.tutoring.co.kr/res/images/evt/com/invite/img_w_how01.png" alt="title" />
        </div>
        <img
          class="down-arrow"
          src="//res.tutoring.co.kr/res/images/evt/com/invite/ic_arrow_referral.png"
          alt="arrow"
        />
        <div class="card">
          <img class="img270" src="//res.tutoring.co.kr/res/images/evt/com/invite/img_w_how02.png" alt="title" />
        </div>
        <img
          class="down-arrow"
          src="//res.tutoring.co.kr/res/images/evt/com/invite/ic_arrow_referral.png"
          alt="arrow"
        />
        <div class="card">
          <img class="img270" src="//res.tutoring.co.kr/res/images/evt/com/invite/img_w_how03.png" alt="title" />
        </div>
        <img
          class="down-arrow"
          src="//res.tutoring.co.kr/res/images/evt/com/invite/ic_arrow_referral.png"
          alt="arrow"
        />
        <div class="card">
          <img class="img270" src="//res.tutoring.co.kr/res/images/evt/com/invite/img_w_how04.png" alt="title" />
        </div>
      </section>
      <section class="participationWeb">
        <img
          class="titleImg"
          src="//res.tutoring.co.kr/res/images/evt/com/invite/img_tl_03.png"
          alt="title"
          style="margin-bottom: 40px"
        />
        <div class="container">
          <div class="box">
            <div class="card">
              <img class="img270" src="//res.tutoring.co.kr/res/images/evt/com/invite/img_w_how01.png" alt="title" />
            </div>
            <img
              class="down-arrow"
              src="//res.tutoring.co.kr/res/images/evt/com/invite/ic_arrow_referral.png"
              alt="arrow"
              style="visibility: hidden"
            />
            <div class="card">
              <img class="img270" src="//res.tutoring.co.kr/res/images/evt/com/invite/img_w_how04.png" alt="title" />
            </div>
          </div>
          <div class="box">
            <img
              class="right-arrow"
              src="//res.tutoring.co.kr/res/images/evt/com/invite/ic_arrow_referral.png"
              alt="arrow"
            />
            <img
              class="down-arrow"
              src="//res.tutoring.co.kr/res/images/evt/com/invite/ic_arrow_referral.png"
              alt="arrow"
              style="visibility: hidden"
            />
            <img
              class="left-arrow"
              src="//res.tutoring.co.kr/res/images/evt/com/invite/ic_arrow_referral.png"
              alt="arrow"
            />
          </div>
          <div class="box">
            <div class="card">
              <img class="img270" src="//res.tutoring.co.kr/res/images/evt/com/invite/img_w_how02.png" alt="title" />
            </div>
            <img
              class="down-arrow"
              src="//res.tutoring.co.kr/res/images/evt/com/invite/ic_arrow_referral.png"
              alt="arrow"
            />
            <div class="card">
              <img class="img270" src="//res.tutoring.co.kr/res/images/evt/com/invite/img_w_how03.png" alt="title" />
            </div>
          </div>
        </div>
      </section>
      <section class="tutoringInfo">
        <img
          class="titleImg"
          src="//res.tutoring.co.kr/res/images/evt/com/invite/img_tl_05.png"
          alt="title"
          style="margin-bottom: 50px"
        />
        <div class="tutoringInfoContainer">
          <div class="card">
            <p class="title">
              국민 가격으로 즐기는
              <br />
              최고 수준의 수업
            </p>
            <img class="image" src="//res.tutoring.co.kr/res/images/evt/com/invite/img_about_01.png" alt="info" />
          </div>
          <div class="card">
            <p class="title">
              국내 최처 24시간
              <br />
              언제 어디서나 수업 진행
            </p>
            <img class="image" src="//res.tutoring.co.kr/res/images/evt/com/invite/img_about_02.png" alt="info" />
          </div>
          <div class="card">
            <p class="title">
              전문 학습 컨설턴트와의
              <br />
              학습 상담 및 코칭 제공
            </p>
            <img class="image" src="//res.tutoring.co.kr/res/images/evt/com/invite/img_about_03.png" alt="info" />
          </div>
        </div>
      </section>
      <section class="detail">
        <p class="title">유의사항</p>
        <ul class="desc">
          <li>본 이벤트를 통해 지급받은 포인트는 정기 결제를 제외한 모든 수강권 구매 시 사용 가능합니다.</li>
          <li>상세한 포인트 적립 및 사용, 환불 조건은 튜터링 내부 정책을 따릅니다.</li>
          <li>적립한 포인트는 튜터링 앱 [마이튜터링] > [포인트&포인트몰] > 나의 포인트에서 확인하실 수 있습니다.</li>
          <p class="top">추천인 안내사항</p>
          <li>
            본 이벤트는 튜터링 영어 수강권 구매 이력이 있는 회원에 한해 참여 가능하며, B2B 회원은 참여가 불가합니다.
          </li>
          <li>추천인 포인트는 초대 링크를 통해 가입한 친구가 첫 수업 완료 시 자동 지급됩니다.</li>
          <li>추천인 포인트 사용 기한은 적립일로부터 1년이며, 이후 소멸됩니다.</li>
          <p class="top">피추천인 안내사항</p>
          <li>본 이벤트는 초대 링크를 통해 신규 가입한 회원에 한해 참여 가능합니다.</li>
          <li>피추천인 포인트는 회원 가입 완료 시 / APP에서 첫 수업 완료 시 자동 지급됩니다.</li>
          <li>피추천인 포인트 사용 기한은 적립일로부터 1년이며, 이후 소멸 됩니다.</li>
          <p class="top">이벤트 유의사항</p>
          <li>
            본 이벤트의 취지에 맞지 않는 부정 행위가 확인되는 경우, 이벤트 포인트 회수 및 이벤트 참여를 제한할 수
            있습니다.
          </li>
          <li>본 이벤트는 사전 예고 없이 주최 측 사정에 의해 변경되거나 종료될 수 있습니다.</li>
        </ul>
      </section>
      <section class="challenge-cta is-info" :class="{ 'ani-bottom-up': bottomSheetUp }" id="challengeCta">
        <!-- l-select 에 is-on 넣을때 얘도 같이 is-on 넣어주세요  -->
        <div class="dim"></div>
        <div class="challenge-cta__wrap">
          <div class="wrapper">
            <div class="btnwrap">
              <button
                type="button"
                class="cta__mobile cta__button tamo-layer tablet-mobile"
                data-status="N"
                @click="pushToLogin()"
              >
                <span class="btnText">10초 만에 가입하고 10,000P 받기</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script lang="ts">
import promotionMixins from '../../common/mixins/promotion';

export default {
  name: 'RecommendEvent',
  components: {},
  mixins: [promotionMixins],
  data() {
    return { referral_code: '' as string };
  },
  created() {
    this.referral_code = this.$route.params.referral_code;
  },
  methods: {
    pushToLogin() {
      window.location.href = `/home/login?referral_code=${this.referral_code}`;
    },
  },
  computed: {},
};
</script>

<style>
.page-recommend {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.page-recommend section {
  flex: 1;
}
.page-recommend .titleImg {
  display: block;
  width: 246px;
  margin: 0 auto;
}
.back-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-left: -14px;
  padding: 0 14px;
}
.main {
  background-color: #9a52ff;
}
.main .textContainer .textImg {
  display: block;
  width: 236px;
  margin: 50px auto 30px auto;
}
.main .textContainer .text {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #fff;
}
.main .mainImg {
  display: block;
  width: 100%;
  max-width: 408px;
  margin: 0 auto;
}
.bottom {
  width: 100%;
  padding: 20px 0;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  color: #fed33d;
  background-color: #371469;
}
.benefit {
  background-color: #d6d3dd;
  padding: 100px 30px;
}
.benefit .sub-title {
  font-size: 26px;
  font-weight: 800;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 22px;
}
.benefit .card {
  width: 300px;
  border-radius: 30px;
  text-align: center;
  padding: 20px 0;
  margin: 0 auto 10px auto;
}
.benefit .card-white {
  background-color: #fff;
}
.benefit .card-white .point {
  font-size: 46px;
  font-weight: 900;
  color: #333;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.benefit .card-white .point img {
  width: 40px;
  height: 40px;
  margin-top: 3px;
  margin-left: 8px;
}
.benefit .card-white .desc1 {
  font-size: 14px;
  font-weight: 600;
  color: gray;
  line-height: 20px;
}
.benefit .card-white .desc2 {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #9a52ff;
}
.benefit .card-purple {
  padding: 10px 0;
  background-color: #9a52ff;
}
.benefit .card-purple img {
  width: 282px;
}
.benefit button {
  width: 300px;
  height: 56px;
  background: #fed33d;
  border: 2px solid #3b0f46;
  border-radius: 10px;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.5px;
  color: #121214;
  display: block;
  margin: 50px auto 0 auto;
}
.participation {
  background-color: #efe6fb;
  padding: 100px 30px;
}
.participation .card {
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #c6b7da;
  border-radius: 30px;
  width: 300px;
  margin: 0 auto;
  padding: 30px 0;
}
.participation .card img {
  display: block;
  margin: 0 auto;
}
.participation .card .img270 {
  width: 270px;
}
.participation .down-arrow {
  display: block;
  width: 40px;
  margin: 30px auto;
}
.tutoringInfo {
  background-color: #9a52ff;
  padding: 100px 30px;
}
.tutoringInfo .card .title {
  font-weight: 800;
  font-size: 26px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
}
.tutoringInfo .card .image {
  display: block;
  width: 300px;
  height: 329px;
  margin: 0 auto 50px auto;
}
.detail {
  background-color: #371469;
  padding: 60px 30px 90px 30px;
}
.detail .title {
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: #fff;
  text-align: center;
  border-bottom: solid 1px rgba(213, 184, 255, 0.3);
  padding-bottom: 20px;
  margin-bottom: 30px;
}
.detail .desc {
  font-weight: 400;
  font-size: 10px;
  line-height: 17px;
  letter-spacing: -0.5px;
  color: #b680ff;
}
.detail .desc .top {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -1px;
  color: #d5b8ff;
  margin: 24px 0 10px 0;
}
.detail .desc li {
  list-style-type: disc;
  margin-left: 30px;
}
@media (max-width: 991px) {
  .participationWeb {
    display: none;
  }
}
@media (min-width: 992px) {
  .main {
    display: flex;
    align-items: end;
    justify-content: center;
    padding: 70px 30px;
  }
  .main .mainImg {
    margin: 0 0 0 100px;
  }
  .main .textContainer .text {
    text-align: left;
  }
  .benefit .benefitContainer {
    display: flex;
    justify-content: center;
  }
  .benefit .benefitContainer .cardContainer {
    margin: 0 15px;
  }
  .tutoringInfo .tutoringInfoContainer {
    display: flex;
    justify-content: center;
  }
  .tutoringInfo .tutoringInfoContainer .card {
    margin: 0 15px;
  }
  .participationWeb {
    background-color: #efe6fb;
    padding: 100px 30px;
  }
  .participationWeb .container {
    display: flex;
    justify-content: center;
  }
  .participationWeb .container .box {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .participationWeb .container .box .right-arrow {
    display: block;
    width: 40px;
    margin: 30px 25px;
    transform: rotate(270deg);
  }
  .participationWeb .container .box .left-arrow {
    display: block;
    width: 40px;
    margin: 30px 25px;
    transform: rotate(90deg);
  }
  .participationWeb .card {
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid #c6b7da;
    border-radius: 30px;
    width: 430px;
    margin: 0 auto;
    padding: 30px 0;
  }
  .participationWeb .card img {
    display: block;
    margin: 0 auto;
  }
  .participationWeb .card .img270 {
    width: 270px;
  }
  .participationWeb .down-arrow {
    display: block;
    width: 40px;
    margin: 30px auto;
  }
  .participation {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
/* 구매하기 cta */
.challenge-cta {
  position: fixed;
  bottom: -100%;
  right: 0;
  left: 0;
  z-index: 50;
  transition: bottom 0.4s;
  @media (max-width: 1199px) {
    & {
      bottom: 0;
    }
  }

  &.ani-bottom-up {
    bottom: 0;
  }

  & .challenge-cta__wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(55, 20, 105, 0.8);
    backdrop-filter: blur(20px);
    height: 84px;

    @media (max-width: 1199px) {
      & {
        height: 60px;
        padding: 10px 30px;
      }
    }
    & .wrapper {
      width: 100%;
      max-width: 672px;

      & button {
        display: flex;
        width: 100%;
        max-width: 600px;
        height: 50px;
        background: #fed33d;
        border-radius: 10px;
        font-weight: 800;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.5px;
        color: #121214;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: -1px;
        margin: 0 auto;
      }
    }
  }
}
// 하단 버튼 작업
.challenge-cta.is-info {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 50;
  color: #000;
  transition: bottom 0.4s;
}

.challenge-cta.is-info .challenge-cta__wrap {
  border-top: none;
}

.promotion .challenge-cta .challenge-cta__wrap .wrapper .textwrap .heading {
  display: inline-flex;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  color: #999999;
  margin-right: 5px;
}

.promotion .challenge-cta .challenge-cta__wrap .wrapper .textwrap .date {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #ff1212;
  color: #ffffff;
  padding: 5px 13px;
  border-radius: 20px;
  margin-bottom: 10px;
  line-height: 14px;
  font-size: 13px;
  font-weight: 700;
}

.promotion .challenge-cta .challenge-cta__wrap .wrapper .textwrap .time {
  font-weight: 700;
  font-size: 17px;
  line-height: 17px;
  color: #000;
}
</style>
